var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-details"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "page-details__container"
  }, [_c('div', {
    staticClass: "page-details__actions"
  }, [_c('sygni-rounded-button', {
    staticClass: "outline hover-bg-white",
    attrs: {
      "arrowIconLeft": true
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Go back ")]), _c('sygni-rounded-button', {
    staticClass: "filled red",
    attrs: {
      "disabled": _vm.disableSaveToPdf
    },
    on: {
      "click": function click($event) {
        return _vm.printPage();
      }
    }
  }, [_vm._v("Save to pdf")])], 1)])]), _c('div', {
    staticClass: "col-9 col-xl-10"
  }, [_c('div', {
    class: ['drag-button', _vm.dragActive ? 'drag-button--active' : ''],
    on: {
      "dragenter": function dragenter($event) {
        _vm.dragActive = true;
      },
      "dragleave": function dragleave($event) {
        _vm.dragActive = false;
      },
      "dragover": _vm.dragFileOver,
      "drop": function drop($event) {
        return _vm.uploadXmlFile($event, $event.dataTransfer.files, true);
      }
    }
  }, [_vm._m(0), _c('p', {
    staticClass: "drag-button__text"
  }, [_vm._v("Drag and drop your file")])]), _c('div', {
    staticClass: "page-details__title"
  }, [_c('p', {
    staticClass: "page-details__category"
  }, [_vm._v(_vm._s(_vm.category))]), _c('h1', {
    staticClass: "page-details__header"
  }, [_vm._v(_vm._s(_vm.title))])])]), _c('div', {
    staticClass: "col-3 col-xl-2"
  }, [_c('div', {
    staticClass: "page-details__content-col"
  }, [_c('label', {
    staticClass: "upload-button",
    attrs: {
      "for": "fileUpload1"
    }
  }, [_vm._v(" Upload from computer "), _c('input', {
    ref: "fileUpload1",
    attrs: {
      "id": "fileUpload1",
      "accept": "text/xml",
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.uploadXmlFile($event, $event.target.files, true);
      }
    }
  })]), _c('p', {
    staticClass: "xml-page-details__signature"
  }, [_vm._v("File format: xml / max 5mb")])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "drag-button__icon"
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": require("@/assets/icons/xml.svg")
    }
  })]);
}]

export { render, staticRenderFns }